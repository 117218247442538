import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  // properties
  private storage$ = new Subject<string>();

  constructor() { }

  watchStorage(): Observable<any> {
    return  this.storage$.asObservable();
  }

  SetItem(key: string, data: any) {
    localStorage.setItem(key, data);
    this.storage$.next(data);
  }

  RemoveItem(key: string) {
    localStorage.removeItem(key);
    this.storage$.next(`Removido item: ${key}`);
  }
}
