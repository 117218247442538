import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DataModel } from '../models/data.model';

@Injectable({
  providedIn: 'root'
})
export class BackendService {

  private urlApi: string;
  public identity: any;
  public token = 'Q0xJRU5UX0FQUC0zODQ0MWVkMjozMGJBc2lsZWlBQDA';
  public DatosUsuario: any;
  public rol: string;
  public Headers: any;


  constructor(private http: HttpClient) {
    this.urlApi = environment.apiUrl;
    this.Headers = new HttpHeaders();
    this.Headers = this.Headers.set('Content-Type', 'application/json');
  }

    // Select
    dataTiposDocumento = [
      { label: 'Cédula de ciudadanía', value: 'C' },
      { label: 'Cédula de extranjería', value: 'E' },
      { label: 'Nit', value: 'N' },
      { label: 'Pasaporte', value: 'P' },
      { label: 'Tarjeta identidad', value: 'T' },
    ];

    getSearch({tipo_documento, documento}: DataModel, pathUpdateOrGet: string) {
      this.Headers = new HttpHeaders();
      // this.Headers = this.Headers.set('Content-Type', 'application/json');
      this.Headers = this.Headers.set('Content-Type', 'application/x-www-form-urlencoded');
      this.Headers = this.Headers.set('token', this.token);
      this.Headers = this.Headers.set('authorization', this.token);

      // Hay 2 endpoints uno para obtener el resumen y otro para refrescar los mismos datos.
      if(pathUpdateOrGet === 'update') {
         pathUpdateOrGet = 'actualizar_resumen_compradores';
      } else {
         pathUpdateOrGet = 'resumen_compradores';
      }

      const options = { headers: this.Headers};
      return this.http.get(`${this.urlApi}/${pathUpdateOrGet}?tipo_documento=${tipo_documento}&documento=${documento}`, options);
    }

    getPaginate(params: any) {
      this.Headers = new HttpHeaders();
      // this.Headers = this.Headers.set('Content-Type', 'application/json');
      this.Headers = this.Headers.set('Content-Type', 'application/x-www-form-urlencoded');
      this.Headers = this.Headers.set('token', this.token);
      this.Headers = this.Headers.set('authorization', this.token);
      const options = { headers: this.Headers, params };
      return this.http.get(`${this.urlApi}/resumen_tabla_compradores`, options);
    }

}
