import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm, FormGroup, FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { LoginModel } from 'src/app/models/login.model';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  usuario: LoginModel = new LoginModel();
  DatosUsuario: any;
  FormLogin: FormGroup;
  Datos: any = {};
  public token: string;
  public title: string;
  public hidePassword = false;


  public identity: object = {
    NOMBRE: '',
  };

  // Ajax
  DataRolOpciones: any;
  DataRolUsuario: any;

  ObjLogin = {
    usuario: '',
    contrasena: ''
  };


  constructor( private auth: AuthService,
               private router: Router,
               private spinner: NgxSpinnerService,
               private toastr: ToastrService) {

                this.FormLogin = new FormGroup({
                  usuario: new FormControl('', [Validators.required, Validators.minLength(4)]),
                  contrasena: new FormControl('', [Validators.required, Validators.minLength(4)]),
                });

                this.FormLogin.setValue( this.ObjLogin );

    }

  ngOnInit() {
    if ( localStorage.getItem('usuarioCFRC') ) {
      this.usuario.usuario = localStorage.getItem('usuarioCFRC');
    }
  }

  login( form: NgForm ) {
    if (  form.invalid ) { return; }
    this.spinner.show();

    this.auth.login( this.FormLogin.value )
      .subscribe( (resp: any) => {

       if (resp && resp.TOKEN) {
        this.identity = resp;
        localStorage.setItem('identityCFRC', JSON.stringify(resp));
        this.DatosUsuario = JSON.parse(localStorage.getItem('identityCFRC'));
        this.router.navigateByUrl('/resumen-compradores');
        this.toastr.success(`${resp.NOMBRE}`, 'Hola:', {
          timeOut: 6000
        });
       }

       this.spinner.hide();

      }, (err) => {
        this.spinner.hide();

        Swal.fire({
          type: 'error',
          title: 'Usuario o contraseña incorrectos.',
          text: err.error.message,
        });
      });

  }

  togglePassword() {
    this.hidePassword = !this.hidePassword;
   }

  toastNotification(title: string, description: string) {
   this.toastr.success(title, description);
  }

}

