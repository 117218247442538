import { Component, OnInit, Output, EventEmitter, Input, } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  public pages = [];
  public selectedPage = 0;
  public limit = 5;
  public itemsPerPage = [
    { value: 5, label: 5 },
    { value: 25, label: 25 },
    { value: 50, label: 50 },
    { value: 100, label: 100 },
  ];
  @Output() idSelectedPage: EventEmitter<object> = new EventEmitter();
  @Input() table: string;
  @Input() cantPages: number;

  constructor() {
  }

  ngOnInit() {
    this.pages = this.createPages(this.cantPages);
  }

  selectPage(idx: number) {
     this.selectedPage = idx;
     this.idSelectedPage.emit({
      pagina_actual: this.selectedPage + 1,
      tabla: this.table,
      limite: this.limit,
    });
  }

  nextPage() {
    if (this.selectedPage >= this.pages.length - 1) {
      return;
    }
    this.selectedPage ++;
    this.idSelectedPage.emit({
      pagina_actual: this.selectedPage + 1,
      tabla: this.table,
      limite: this.limit,
    });
  }

  previousPage() {
    if (!this.selectedPage) {
      return;
    }
    this.selectedPage --;
    this.idSelectedPage.emit({
      pagina_actual: this.selectedPage + 1,
      tabla: this.table,
      limite: this.limit,
    });
  }

  limitChange() {
    this.idSelectedPage.emit({
      pagina_actual: this.selectedPage + 1,
      tabla: this.table,
      limite: this.limit,
    });
  }

  createPages(cantPages: number) {
    return Array.from(new Array(cantPages), (x, i) => i + 1);
  }

}
