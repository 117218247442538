import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
declare let gtag: any;

@Component({
  selector: 'app-whatsapp',
  templateUrl: './whatsapp.component.html',
  styleUrls: ['./whatsapp.component.scss']
})
export class WhatsappComponent implements OnInit {

  // Properties
  whastAppMessage = '';
  cellphone = 573112360179;
  user: string;
  identity: any;
  Analytics: any;

  // Toggles
  togglePopUp = false;

  // Angular
  @ViewChild('message') message: ElementRef;

  constructor() {
    this.identity = JSON.parse(localStorage.getItem('identityMA'));
  }

  ngOnInit() {
    this.identity = JSON.parse(localStorage.getItem('identityMA'));
    this.Analytics = JSON.parse(localStorage.getItem('identityMA'));
    const userName = this.identity.NOMBRE || '';
    this.user = userName.split(' ')[0];
  }

  TogglePopUp() {
    // this.togglePopUp = !this.togglePopUp;
    if (this.togglePopUp === true) {
      this.togglePopUp = false;
    } else if (this.togglePopUp === false) {
      this.togglePopUp = true;
      setTimeout(() => {
        this.message.nativeElement.focus();
      }, 0);
    }
  }

  SendMessage(message: string) {
    if (message === '' || message === undefined || message === null) {
      message = 'Quiero recibir más información.';
    }
    const { NOMBRE, DOCUMENTO } = this.identity;
    const app = `(MedicApp)`;
    const text = `Hola soy: ${NOMBRE}, cédula: ${DOCUMENTO} - ${app}: ${message}`;

    const url = `https://api.whatsapp.com/send?phone=${this.cellphone}&text=${text}`;

    (window as any).gtag('event', 'mensaje_whatsapp', {
      event_category: `${this.Analytics.TIPO_DOCUMENTO}${this.Analytics.DOCUMENTO}`, event_label: ``
      });

    window.open(url, '_blank');

    setTimeout(() => {
      this.whastAppMessage = '';
    }, 3000);
  }

}
