export class DataModel {
    documento?: string = '';
    tipo_documento?: string = '';
    periodo?: string = '';

    // pagination
    tabla?: string;
    pagina_actual?: number;
    limite?: number;
}
