import { Component, OnInit, HostListener, DoCheck,  } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { StorageService } from './services/storage.service';
import { filter } from 'rxjs/operators';

declare let $: any;
declare var gtag: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, DoCheck {
   // Declare height and width variables
    innerWidth: any;
    screenHeight: any;
    isShowWidth: boolean;
    isShowHeigth: boolean;
    @HostListener('window:resize', ['$event'])
    onResize(event) {

    this.screenHeight = window.innerHeight;
    this.innerWidth = window.innerWidth;


    if (this.screenHeight >= 1040) {
         this.isShowHeigth = true;
       } else {
         this.isShowHeigth = false;
      }

    if (this.innerWidth >= 900) {
         this.isShowWidth = true;
       } else {
       this.isShowWidth = false;
       }
    }

    // Properties
    protected ngUnsubscribe: Subject<void> = new Subject<void>();
    public identity: any;
    public year: number = Date.now();
    public toggleNav = false;
    public request = null;
    public logoColapse = false;
    public title = 'MedicApp';
    public DatosUsuario: any;
    public IsLogin: any;
    public IsLogged: boolean;
    public administrator = false;
    public toggleDropdown = false;

    // Analytics
    public Analytics: any;

    // Document size
    public isShow: boolean;
    public topPosToStartShowing = 100;

    @HostListener('window:scroll')
    checkScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    if (scrollPosition >= this.topPosToStartShowing) {
      this.isShow = true;
    } else {
      this.isShow = false;
     }
    }

  // Ajax

  constructor( public router: Router,
               public auth: AuthService,
               private toastr: ToastrService,
               private authService: AuthService,
               public storageService: StorageService) {
                 // Analitycs
                 const navEndEvent$ = this.router.events
                                          .pipe(
                                             filter(event => event instanceof NavigationEnd)
                                          );

                 navEndEvent$.subscribe((event: NavigationEnd) => {
                     gtag('config', '', {
                        page_path: event.urlAfterRedirects
                      });
                    });
                 // ./Analitycs

               }

  ngOnInit() {
   console.info(new Date(2020, 11, 14));
  }

  ngDoCheck() {
   this.DatosUsuario = JSON.parse(localStorage.getItem('identityCFRC'));
  }

  // TODO: Cross browsing
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  // Cerrar Sesión
  logOut() {
    this.router.navigate(['/login']);
    localStorage.clear();
  }

  // Mensajes Toast
  ToastNotification(titulo: string, descripcion: string) {
   this.toastr.success(titulo, descripcion);
  }

}

