import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';

/*Switch*/
import { UiSwitchModule } from 'ngx-ui-switch';

/*Toaster*/
import { ToastrModule } from 'ngx-toastr';

/*Spinner*/
import { NgxSpinnerModule } from 'ngx-spinner';

/*DatePicker*/
import { OwlDateTimeModule, OwlNativeDateTimeModule, OwlDateTimeIntl } from 'ng-pick-datetime';
import { NgModule, LOCALE_ID  } from '@angular/core';
import { OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { registerLocaleData } from '@angular/common';
import localeEsCO from '@angular/common/locales/es-CO';

/*HTTP*/
import { HttpClientModule } from '@angular/common/http';

/* Virtual Scroll */
import { ScrollingModule } from '@angular/cdk/scrolling';
import { Ng2SearchPipeModule } from 'ng2-search-filter';

/* Rutas */
import { APP_ROUTING } from './app.routes';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

// Directivas
import { OutsideDirective } from './directives/outside.directive';
import { UppercaseDirective } from './directives/uppercase.directive';
import { } from '@angular/cdk/keycodes';
import { OnlyNumberDirective } from './directives/only-number.directive';
import { AuthService } from './services/auth.service';
import { FilterPipe } from './pipes/filter.pipe';
import { WhatsappComponent } from './components/whatsapp/whatsapp.component';
import { ResumenCompradoresComponent } from './pages/resumen-compradores/resumen-compradores.component';
import { LoginComponent } from './components/login/login.component';
import { PaginationComponent } from './components/pagination/pagination.component';

registerLocaleData(localeEsCO, 'es-Co');

export class DefaultIntl extends OwlDateTimeIntl  {
  rangeFromLabel = 'Desde';
  rangeToLabel = 'Hasta';
  hour12AMLabel = 'AM';
  hour12PMLabel = 'PM';
  prevMultiYearLabel = '21 años atrás';
  nextMultiYearLabel = 'Próximos 21 años';
}

@NgModule({
  declarations: [
    AppComponent,
    OutsideDirective,
    UppercaseDirective,
    OnlyNumberDirective,
    FilterPipe,
    WhatsappComponent,
    ResumenCompradoresComponent,
    LoginComponent,
    PaginationComponent,
  ],
  imports: [
    BrowserModule,
    NgMultiSelectDropDownModule.forRoot(),
    ScrollingModule,
    UiSwitchModule.forRoot({
      size: 'small',
      color: 'rgb(61, 160, 48)',
      switchColor: '#FFFFFF',
      defaultBgColor: 'rgb(151, 151, 151)',
      defaultBoColor : '#dadada',
      checkedLabel: 'Si',
      uncheckedLabel: 'No'
    }),
    Ng2SearchPipeModule,
    CommonModule,
    APP_ROUTING,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    BrowserAnimationsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgxSpinnerModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      progressBar: true,
      progressAnimation: 'decreasing',
      maxOpened: 1,
      autoDismiss: true,
      closeButton: true
    })
  ],
  providers: [
    AuthService,
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'es' },
    { provide: OwlDateTimeIntl, useClass: DefaultIntl },
    { provide: LOCALE_ID, useValue: 'es-Co' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
