import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { IdentityModel } from 'src/app/models/identity.model';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private apiUrl: string;
  userToken: any;
  visible: boolean;

  public administrator = false;

  constructor( private http: HttpClient ) {
    this.apiUrl = environment.apiUrl;
    this.visible = false;
    this.ReadToken();
  }

  login( usuario: any  ) {
    this.IsAutenticated();

    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const options = {
      params: null, headers
    };

    return this.http.post(`${this.apiUrl}/acceso`, usuario, options).pipe(
      map( resp => {
        this.saveToken( resp['TOKEN'] );
        return resp;
      })
    );

  }

  private saveToken( token: string ) {
    this.userToken = token;
    localStorage.setItem('TokenCFRC', token);
  }


  ReadToken() {

    if ( localStorage.getItem('TokenCFRC') ) {
      this.userToken = localStorage.getItem('TokenCFRC');
    } else {
      this.userToken = '';
    }

    return this.userToken;

  }

  logout() {
    localStorage.removeItem('TokenCFRC');
  }

  IsAutenticated(): boolean {
  if (localStorage.getItem('TokenCFRC') === undefined || localStorage.getItem('TokenCFRC') === null) {
      return false;
    } else {
      return this.userToken;
    }
  }


}
