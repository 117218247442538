import { RouterModule, Routes } from '@angular/router';

import { ResumenCompradoresComponent } from './pages/resumen-compradores/resumen-compradores.component';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from './guards/auth.guard';

const APP_ROUTES: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'resumen-compradores', component: ResumenCompradoresComponent, canActivate: [AuthGuard] },
    { path: '**', pathMatch: 'full', redirectTo:  'login' },
];

export const APP_ROUTING = RouterModule.forRoot(APP_ROUTES, { useHash: true, anchorScrolling: 'enabled'});
