import { Component, OnInit } from '@angular/core';
import { BackendService } from 'src/app/services/backend.service';
import { DataModel } from 'src/app/models/data.model';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-resumen-compradores',
  templateUrl: './resumen-compradores.component.html',
  styleUrls: ['./resumen-compradores.component.scss']
})
export class ResumenCompradoresComponent implements OnInit {
  // Models
  public data = new DataModel();
  public pagParams: DataModel = {};

  // Dates
  public year = new Date().getFullYear();

  // Properties
  public dataTiposDocumento: any;
  public cantRegisters = 0;

  public dataPeriodo = [
    { label: 'Trimestral', value: '1' },
    { label: 'Semestral', value: '2' },
    { label: 'Anual', value: '3' },
  ];

  // Ajax
  public dataResume: any;
  public dataPagination: any;
  public dataLocalization: any;
  public dataTransactions: any;
  public dataObligations: any;
  public dataQuotas: any;
  public dataBuyers: any;
  public dataCampaigns: any;
  public dataCustomers: any;

  constructor(private backend: BackendService,
              private toastr: ToastrService,
              private spinner: NgxSpinnerService) {
    this.dataTiposDocumento = this.backend.dataTiposDocumento;
  }

  ngOnInit() {
  }

  search(form?: NgForm, update?: string) {
    if (form && form.invalid) { return; }
    let pathUpdateOrGet = update || 'get';
    this.spinner.show();
    this.dataResume = [];
    this.cantRegisters = 0;
    this.backend.getSearch(this.data, pathUpdateOrGet)
        .subscribe((resp: any) => {

          for (const key in resp) {
            if (resp && resp[key].length > 0 && typeof resp[key] === 'object') {
              this.cantRegisters ++;
            }
          }

          if (this.cantRegisters === 0) {
            this.spinner.hide();
            Swal.fire({
              type: 'error',
              title: 'No hay Información disponible',
              text: 'Por favor verifique los datos ingresados.'
            });
            return;
          }

          this.dataResume = resp;
          this.dataPagination = resp;
          this.dataLocalization = this.dataResume.localizacion_reciente[0];
          this.dataTransactions = this.dataResume.recientes_transacciones;
          this.dataObligations = this.dataResume.obligaciones_en_mora;
          this.dataQuotas = this.dataResume.cupos_asignados;
          this.dataBuyers = this.dataResume.compradores[0];
          this.dataCampaigns = this.dataResume.campanas;
          this.dataCustomers = this.dataResume.clientes_mayor_uso;

          this.spinner.hide();

          this.toastr.success(`${this.dataBuyers.NOMBRES_CLIENTE}`, 'Cliente:', {
            timeOut: 4000
          });
        }, (err: any) => {
          this.dataResume = [];
          this.spinner.hide();
          console.error(err);
          this.toastr.error(`${err}`, 'Error', {
            timeOut: 4000
          });
        });
  }

  paginate(dataPagination: any) {
    this.pagParams = {...this.data, ...dataPagination };

    this.backend.getPaginate(this.pagParams)
        .subscribe((resp: any) => {

          const { tabla } = this.pagParams;

          switch (tabla) {
            case 'localizacion_reciente':
            this.dataLocalization = resp.localizacion_reciente[0];
            break;
            case 'cupos_asignados':
            this.dataQuotas = resp.cupos_asignados;
            break;
            case 'recientes_transacciones':
            this.dataTransactions = resp.recientes_transacciones;
            break;
            case 'clientes_mayor_uso':
            this.dataCustomers = resp.clientes_mayor_uso;
            break;
            case 'obligaciones_en_mora':
            this.dataObligations = resp.obligaciones_en_mora;
            break;
            case 'campanas':
            this.dataCampaigns = resp.campanas;
            break;
            case 'compradores':
            this.dataBuyers = resp.compradores[0];
            break;
          default:
            Swal.fire({
               type: 'error',
               title: 'Por favor intente más tarde',
               text: 'O comuníquese con el area encargada'
             });
            break;
          }
        }, (err: any) => {
          console.log(err);
          Swal.fire({
            type: 'error',
            title: 'Por favor intente más tarde',
            text: err
          });
        });
  }

}
